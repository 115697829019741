<template>
  <div  class="content ma-5 mb-16 pb-8 px-0 px-sm-16  mx-sm-12" >

    <div class="justify-space-around d-none d-md-flex"  >
      <div class="" v-for="tag in tags" :key="tag">
          <v-btn class="tagbtn" plain text x-small :href="'#'+tag">
            {{tag}}
          </v-btn>
      </div>
    </div>

    <div class=" d-flex py-6">
      <v-row>
        <v-col cols="3" class="pointer d-none d-md-block" >
          <v-img :src="domain + brand_banner.adv_image" class="white--text align-end" @click="$router.push(brand_banner.adv_url)">
            <div class="top-title text-subtitle-1 pl-4 ">{{brand_banner.title}}</div>
            <div class="top-subtitle  pl-4 mb-4">{{brand_banner.subtitle}}</div>
          </v-img>
        </v-col>
        <v-col cols="10" md="8">
          <v-card class="" flat till >
            <div class="my-8" v-for="brand in brands" :key="brand.initial">
              <div class="pb-4 brandtitle text-h6 font-weight-bold">
                <a :name="brand.initial">
                <v-chip class="ma-2 text-h5 font-weight-bold pa-3" label color="black" text-color="white">
                  {{brand.initial}}
                </v-chip>
                </a>
              </div>
              <div class="">
                <v-row>
                  <v-col class="py-0 py-md-4" v-for="item in brand.data" :key="item.brand_id" cols=12 md=4>
                    <div class="mx-3 my-1 mx-md-4 text-h6 plainbtn"  @click="$router.push('/itemlist?brand='+item.brand_id)">
                      {{item.brand_name}}
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card>

        </v-col>
        <v-col cols="1" md="0" class="d-block d-md-none">
          <div class="d-flex flex-column pt-12 "  >
            <div class="ml-4" v-for="tag in tags" :key="tag">
                <v-btn class="tagbtn" plain text x-small :href="'#'+tag">
                  {{tag}}
                </v-btn>
            </div>
          </div>

        </v-col>
      </v-row>
    </div>




  </div>
</template>

<script>

import userApi from '@/api/user'
import { mapState } from 'vuex'



export default {


  data: () => ({
    tags : ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','09'],
    alllist : [],
    brands : []

    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
      brand_banner: state => state.app.brand_banner,
    }),
    colHeight : function() { return window.innerHeight+100 }
  },
  created() {

    userApi.brandlist({page_size:999}).then(res => {
      this.alllist = res.data.data
      this.alllist.forEach(item => {
        let found = false
        for(let i=0; i<this.brands.length; i++)
        {
          let brand = this.brands[i]
          if (brand.initial == item.brand_initial){
            this.brands[i].data.push(item)
            found = true
            break
          }
        }
        if (found == false){
          this.brands.push({initial:item.brand_initial,data:[item]})
        }
      })
      console.log(this.brands)


      
    })

    console.log(90,this.brand_banner)
    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.tagbtn {
  font-weight: 800;
  font-size:17px;
  color:black;
  height: 27px !important;
} 


</style>

